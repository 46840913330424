import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { User } from '@/features/users/types';
import { generateId } from '@/features/utils';

import data from './mock';

type UsersState = {
  data: User[];
};

const initialState: UsersState = {
  data,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsersSuccess: (state, { payload }: PayloadAction<User[]>) => {
      state.data = payload;
    },
    addUsersSuccess: (state, { payload }: PayloadAction<User>) => {
      state.data.push({
        id: generateId(state),
        ...payload,
      });
    },
    updateUsersSuccess: (state, { payload }: PayloadAction<User>) => {
      const idx = state.data.findIndex(user => user.id === payload.id);
      state.data[idx] = payload;
    },
  },
  selectors: {
    usersSelector: (state): User[] => state.data,
    userByIdSelector: (state, id: string): User | undefined =>
      id ? state.data.find(user => user.id === id) : undefined,
  },
});

export const { getUsersSuccess, addUsersSuccess, updateUsersSuccess } =
  usersSlice.actions;

export const { usersSelector, userByIdSelector } = usersSlice.selectors;

export default usersSlice;
