import { styled } from '@mui/material';
import Box from '@mui/material/Box';

import LogoSortIcon from '../../assets/logo-short.svg?react';
import LogoIcon from '../../assets/logo.svg?react';
import useUi from '../../hooks/useUi';
import AdminPanel from './components/AdminPanel';
import Nav from './components/Nav';

const Header = styled('header')({
  padding: '16px',
  svg: {
    display: 'block',
  },
});

const NavWrapper = styled('div')({
  padding: '10px 12px',
});

export const SIDEBAR_FULL_WIDTH = 256;
export const SIDEBAR_SHRINK_WIDTH = 78;

const Sidebar = () => {
  const [minimized] = useUi();
  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        width: minimized ? SIDEBAR_SHRINK_WIDTH : SIDEBAR_FULL_WIDTH,
        height: '100vh',
        background: theme => theme.palette.text.primary,
        zIndex: 1000,
      }}
    >
      <Header>
        {minimized ? (
          <LogoSortIcon
            height={24}
            style={{
              margin: '0 auto',
            }}
          />
        ) : (
          <LogoIcon height={24} />
        )}
      </Header>
      <NavWrapper>
        <Nav />
      </NavWrapper>
      <AdminPanel />
    </Box>
  );
};

export default Sidebar;
