import axios from 'axios';

import { getSessionPayload } from '@/hooks/useAuth';

const setAuthorizationHeader = config => {
  const payload = getSessionPayload();
  if (payload) {
    config.headers.Authorization = `Bearer ${payload.access_token}`;
  }
  return config;
};

const onReqRejected = error => Promise.reject(error);

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

instance.interceptors.request.use(setAuthorizationHeader, onReqRejected);

export default instance;
