import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

export interface LoginCredentials {
  userName: string;
  password: string;
}

export interface LoginRes {
  jwtToken: string;
  refreshToken: string;
}

export interface RequestPasswordResetInput {
  email: string;
}

export interface ResetPasswordInput {
  userId: string;
  token: string;
  newPassword: string;
}

export interface ChangePasswordInput {
  userId: string;
  currentPassword: string;
  newPassword: string;
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: axiosBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL,
  }),
  endpoints: builder => ({
    login: builder.mutation<LoginRes, LoginCredentials>({
      query: input => ({
        url: 'auth/login',
        method: 'POST',
        data: input,
      }),
    }),
    requestPasswordReset: builder.mutation<any, RequestPasswordResetInput>({
      query: input => ({
        url: 'users/request-password-reset',
        method: 'POST',
        data: input,
      }),
    }),
    resetPassword: builder.mutation<any, ResetPasswordInput>({
      query: ({ userId, ...input }) => ({
        url: `users/${userId}/reset-password`,
        method: 'POST',
        data: input,
      }),
    }),
    changePassword: builder.mutation<any, ChangePasswordInput>({
      query: ({ userId, ...input }) => ({
        url: `users/${userId}/change-password`,
        method: 'POST',
        data: input,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRequestPasswordResetMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
} = authApi;
