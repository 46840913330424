import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/utils/axiosBaseQuery';

import { User } from './types';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: axiosBaseQuery({
    baseUrl: import.meta.env.VITE_API_URL,
  }),
  endpoints: builder => ({
    getUserById: builder.query<User, string>({
      query: userId => ({
        url: `users/${userId}`,
      }),
    }),
  }),
});

export const { useLazyGetUserByIdQuery } = usersApi;
