import NotificationsIcon from '@mui/icons-material/Notifications';
import { alpha } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import UserPanel from './components/UserPanel';

const Header = () => {
  return (
    <AppBar
      sx={{
        position: 'sticky',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '8px 16px',
        gap: 1,
        background: theme => theme.palette.common.white,
      }}
    >
      <Stack
        direction='row'
        divider={
          <Divider
            flexItem
            orientation='vertical'
            sx={{
              background: theme => alpha(theme.palette.neutral['500'], 0.3),
            }}
          />
        }
        spacing={1}
      >
        <UserPanel />
        <IconButton>
          <NotificationsIcon
            sx={{
              width: 24,
              height: 24,
            }}
          />
        </IconButton>
      </Stack>
    </AppBar>
  );
};

export default Header;
