import { MouseEvent, useState } from 'react';

import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import Pages from '@/pages';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { List, ListItem, SvgIconTypeMap, styled } from '@mui/material';
import MuiAvatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const Avatar = styled(MuiAvatar)({ width: 32, height: 32, fontSize: 14 });

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '0 8px',
});

const Trigger = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  cursor: 'pointer',
});

interface MenuLiProps {
  icon: OverridableComponent<SvgIconTypeMap> & { muiName: string };
  children: string;
  onClick: () => void;
}

const MenuLi = ({ icon: Icon, children, onClick }: MenuLiProps) => {
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        padding: 1,
      }}
    >
      <ListItemIcon>
        <Icon fontSize='small' />
      </ListItemIcon>
      <ListItemText>
        <Typography variant='body2'>{children}</Typography>
      </ListItemText>
    </MenuItem>
  );
};

interface OrgLiProps {
  active?: boolean;
  children: string;
}

const OrgLi = ({ active, children }: OrgLiProps) => {
  return (
    <ListItem
      sx={{
        marginTop: 1,
        borderRadius: 2,
        padding: '4px 8px',
        bgcolor: theme => (active ? theme.palette.neutral['200'] : 'none'),
      }}
    >
      <Typography variant='body2'>{children}</Typography>
    </ListItem>
  );
};

const UserPanel = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = !!anchorEl;
  const handleClick = (event: MouseEvent<HTMLDivElement>) =>
    setAnchorEl(event.currentTarget);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Container>
      <Trigger
        id='user-panel-button'
        aria-controls={open ? 'user-panel-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Avatar>СГ</Avatar>
        <Avatar>All</Avatar>
        <ChevronLeftIcon
          sx={{
            color: theme => theme.palette.neutral['500'],
            transform: open ? 'rotate(90deg)' : 'rotate(-90deg)',
            width: 24,
            height: 24,
          }}
        />
      </Trigger>
      <Menu
        id='user-panel-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'user-panel-button',
        }}
        elevation={5}
        slotProps={{
          paper: {
            style: { marginTop: 4, width: 317 },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '.MuiList-root': {
            padding: 0,
          },
        }}
      >
        <Stack
          direction='row'
          sx={{
            padding: '16px 20px 16px 16px',
            gap: 1,
            bgcolor: theme => theme.palette.neutral['100'],
          }}
        >
          <Avatar
            sx={{
              width: 40,
              height: 40,
            }}
          >
            СГ
          </Avatar>
          <Stack
            sx={{
              width: '100%',
            }}
          >
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography variant='body2' fontWeight={500}>
                Савченко Гнат
              </Typography>
              <Chip
                size='small'
                label={
                  <Typography variant='caption' fontWeight={500}>
                    admin
                  </Typography>
                }
                color='primary'
                sx={{
                  padding: 0,
                  height: 'auto',
                }}
              />
            </Stack>
            <Typography variant='caption'>savchenko.gnat@gmail.com</Typography>
          </Stack>
        </Stack>
        <Box
          sx={{
            padding: '12px 16px 0',
          }}
        >
          <Box>
            <Typography
              variant='caption'
              fontWeight={500}
              sx={{
                span: {
                  color: theme => theme.palette.neutral['600'],
                },
              }}
            >
              Мої організації <span>/ 26</span>
            </Typography>
            <List
              sx={{
                marginBottom: '20px',
              }}
            >
              <OrgLi active>Організація №1</OrgLi>
              <OrgLi>Організація №1</OrgLi>
              <OrgLi>Організація №1</OrgLi>
              <OrgLi>Організація №1</OrgLi>
            </List>
          </Box>
          <Divider />
          <Stack sx={{ padding: '12px 0' }}>
            <MenuLi
              icon={PersonIcon}
              onClick={() => {
                handleClose();
                navigate(Pages.CHANGE_PASSWORD);
              }}
            >
              Profile
            </MenuLi>
            <MenuLi icon={SettingsIcon} onClick={handleClose}>
              Account settings
            </MenuLi>
          </Stack>
          <Divider />
          <Stack sx={{ padding: '12px 0' }}>
            <MenuLi icon={LogoutIcon} onClick={() => auth.signoutRedirect()}>
              Logout
            </MenuLi>
          </Stack>
        </Box>
      </Menu>
    </Container>
  );
};

export default UserPanel;
