export default [
  {
    id: 'ТИС000001',
    firstName: 'Олександр',
    lastName: 'Савченко',
    thirdName: 'Миколайович',
    active: true,
    position: 'Диспетчер АДС',
    org: 'Бердичивська філія',
  },
  {
    id: 'ТИС000002',
    firstName: 'Олександр',
    lastName: 'Савченко',
    thirdName: 'Миколайович',
    active: true,
    position: 'Фахівець відділу інспектування',
    org: 'Бердичивська філія',
  },
  {
    id: 'ТИС000003',
    firstName: 'Олександр',
    lastName: 'Савченко',
    thirdName: 'Миколайович',
    active: true,
    position: 'Диспетчер АДС',
    org: 'Бердичивська філія',
  },
  {
    id: 'ТИС000004',
    firstName: 'Олександр',
    lastName: 'Савченко',
    thirdName: 'Миколайович',
    active: false,
    position: 'Бердичивське ЛВУМГ',
    org: 'Бердичивська філія',
  },
] as any[];
