import { StrictMode, Suspense } from 'react';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import AuthProvider from '@/utils/AuthProvider';

import store from './app/store';
import { getRoutes } from './route';
import MaterialProvider from './utils/MaterialProvider';

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <AuthProvider>
      <Provider store={store}>
        <MaterialProvider>
          <Suspense>
            <RouterProvider router={getRoutes()} />
          </Suspense>
        </MaterialProvider>
      </Provider>
    </AuthProvider>
  </StrictMode>,
);
