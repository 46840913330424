import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { authApi } from '@/features/auth/authApi';
import authSlice from '@/features/auth/authSlice';
import employeesSlice from '@/features/employees/employeesSlice';
import organizationsSlice from '@/features/organizations/organizationsSlice';
import subdivisionsSlice from '@/features/subdivisions/subdivisionsSlice';
import uiSlice from '@/features/ui/uiSlice';
import { usersApi } from '@/features/users/usersApi';
import usersSlice from '@/features/users/usersSlice';

const rootReducer = combineReducers({
  [uiSlice.name]: uiSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [organizationsSlice.name]: organizationsSlice.reducer,
  [subdivisionsSlice.name]: subdivisionsSlice.reducer,
  [employeesSlice.name]: employeesSlice.reducer,
  [usersSlice.name]: usersSlice.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([authApi.middleware, usersApi.middleware]),
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
