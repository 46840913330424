import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    isSidebarMinimized: false,
  },
  reducers: {
    toggleSidebar: state => {
      state.isSidebarMinimized = !state.isSidebarMinimized;
    },
  },
  selectors: {
    isSidebarMinimizedSelector: state => state.isSidebarMinimized,
  },
});

export const { toggleSidebar } = uiSlice.actions;

export const { isSidebarMinimizedSelector } = uiSlice.selectors;

export default uiSlice;
