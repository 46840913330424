import { ReactNode } from 'react';

import CssBaseline from '@mui/joy/CssBaseline';
import {
  THEME_ID as JOY_THEME_ID,
  CssVarsProvider as JoyProvider,
} from '@mui/joy/styles';
import {
  THEME_ID as MUI_THEME_ID,
  Experimental_CssVarsProvider as MaterialProvider,
} from '@mui/material/styles';

import { joyTheme, materialTheme } from './theme';

const Provider = ({ children }: { children: ReactNode }) => {
  return (
    <MaterialProvider theme={{ [MUI_THEME_ID]: materialTheme }}>
      <JoyProvider theme={{ [JOY_THEME_ID]: joyTheme }}>
        <CssBaseline />
        {children}
      </JoyProvider>
    </MaterialProvider>
  );
};

export default Provider;
