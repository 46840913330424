import BoltOutlined from '@mui/icons-material/BoltOutlined';
import Box from '@mui/material/Box';

import useUi from '../../../../hooks/useUi';
import NavCategory from '../Nav/NavCategory';

const nav = {
  title: 'Адміністрування',
  items: [
    { icon: <BoltOutlined />, title: 'Користувачі', path: '/users' },
    {
      icon: <BoltOutlined />,
      title: 'Довідники',
      path: '/counterparts',
    },
  ],
};

const AdminPanel = () => {
  const [minimized] = useUi();
  return (
    <Box
      sx={{
        margin: 'auto 12px 24px',
        padding: minimized ? '8px 0' : '8px',
        background: theme => theme.palette.neutral['700'],
        borderRadius: 2,
        '.nav-link ': {
          margin: '0 8px',
          '&.active': {
            '& > .MuiListItemButton-root': {
              background: theme => theme.palette.neutral['200'],
            },
            '& .MuiTypography-root': {
              color: theme => theme.palette.neutral['700'],
            },
            '& .MuiSvgIcon-root': {
              fill: theme => theme.palette.neutral['700'],
            },
          },
          '& > .MuiListItemButton-root': {
            padding: minimized ? '7px 16px' : '5px 8px',
            borderRadius: '8px',
          },
        },
        '.category-label': { paddingRight: 1 },
      }}
    >
      <NavCategory {...nav} />
    </Box>
  );
};

export default AdminPanel;
