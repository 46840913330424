import { Outlet } from 'react-router-dom';

import useUi from '@/hooks/useUi';

import { styled } from '@mui/material';

import Header from '../Header';
import Sidebar, { SIDEBAR_FULL_WIDTH, SIDEBAR_SHRINK_WIDTH } from '../Sidebar';
import SidebarToggleButton from '../SidebarToggleButton';

const Container = styled('div')({
  position: 'relative',
  display: 'flex',
  minHeight: '100vh',
});

const Main = styled('div')({
  flex: 1,
});

const PageLayout = () => {
  const [minimized] = useUi();

  return (
    <Container>
      <Sidebar />
      <Main
        sx={{
          width: minimized ? SIDEBAR_SHRINK_WIDTH : SIDEBAR_FULL_WIDTH,
        }}
      >
        <Header />
        <Outlet />
        <SidebarToggleButton />
      </Main>
    </Container>
  );
};

export default PageLayout;
