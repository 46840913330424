import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { generateId } from '@/features/utils';

import data from './mock';
import { Organization } from './types';

interface OrganizationState {
  data: Organization[];
}

const initialState: OrganizationState = {
  data,
};

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    getOrganizationSuccess: (
      state,
      { payload }: PayloadAction<Organization[]>,
    ) => {
      state.data = payload;
    },
    addOrganizationSuccess: (
      state,
      { payload }: PayloadAction<Organization>,
    ) => {
      state.data.push({
        id: generateId(state),
        ...payload,
      });
    },
    updateOrganizationSuccess: (
      state,
      { payload }: PayloadAction<Organization>,
    ) => {
      const idx = state.data.findIndex(org => org.id === payload.id);
      state.data[idx] = payload;
    },
  },
  selectors: {
    organizationsSelector: state => state.data,
    organizationByIdSelector: (state, id: string): Organization | undefined =>
      id ? state.data.find(org => org.id === id) : undefined,
  },
});

export const {
  getOrganizationSuccess,
  addOrganizationSuccess,
  updateOrganizationSuccess,
} = organizationsSlice.actions;

export const { organizationsSelector, organizationByIdSelector } =
  organizationsSlice.selectors;

export default organizationsSlice;
