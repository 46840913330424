export default [
  {
    id: 'ТИС000001',
    name: 'ЖитомирГазмережі',
    parent: 'ЖитомирГазмережі',
  },
  {
    id: 'ТИС000002',
    name: 'Донецькоблгаз',
    parent: 'ЖитомирГазмережі',
  },
  {
    id: 'ТИС000003',
    name: 'Мелітопольгаз',
    parent: 'ЖитомирГазмережі',
  },
] as any[];
