import { ReactNode, useState } from 'react';

import useUi from '@/hooks/useUi';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import NavItem from './NavItem';

const CategoryLabel = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
});

interface Item {
  title: string;
  icon: ReactNode;
  path: string;
}

interface NavCategoryProps {
  title?: string;
  items: Item[];
}

const NavCategory = ({ title, items }: NavCategoryProps) => {
  const [isSidebarMinimized] = useUi();
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Box
      key={title}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      }}
    >
      {!isSidebarMinimized && title ? (
        <CategoryLabel
          className='category-label'
          onClick={() => setIsOpen(prev => !prev)}
        >
          <Typography
            variant='body2'
            fontWeight={500}
            sx={{
              padding: '4px 0 4px 8px',
              color: theme => theme.palette.background.default,
            }}
          >
            {title}
          </Typography>
          <ChevronLeftIcon
            sx={{
              color: theme => theme.palette.background.default,
              transform: isOpen ? 'rotate(-90deg)' : 'rotate(90deg)',
              width: 18,
              height: 18,
            }}
          />
        </CategoryLabel>
      ) : null}
      {(isSidebarMinimized || isOpen) &&
        items.map(item => (
          <NavItem key={item.title} icon={item.icon} path={item.path}>
            {item.title}
          </NavItem>
        ))}
    </Box>
  );
};

export default NavCategory;
