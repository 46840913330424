export default [
  {
    id: 'ТИС000001',
    shortName: 'ЖитомирГазмережі',
    name: 'Житомирська філія ТОВ “Газорозподільні мережі України”',
    head: '19360936',
    parent: 'Головний Київський офіс',
    edrpoyCode: '19360936',
    eicSymbol: '02',
    eicCode: '56X2000000000016',
    prefix: 'ТИС',
    eicGtcCode: '1X000000001397P',
    docNumber: 'JH98899790',
    docDate: '25.20.2017',
    phone: '+380 97 876 76 76',
    address: 'м. Івано-Франківськ вул. Надрічна 4Б',
    assignee: {
      name: 'Савченко Олександр Миколайович',
      position: 'Начальник відділу продажів',
    },
    startDate: '25.10.2007',
    site: 'https://rv.grmu.com.ua',
    contract: 'Газета “Є правда”  від 24.05.2023',
    logo: 'document_file_name.pdf',
  },
  {
    id: 'ТИС000002',
    name: 'Донецькоблгаз',
    eicCode: '56X2000000000016',
    head: '19360936',
  },
  {
    id: 'ТИС000003',
    name: 'Мелітопольгаз',
    eicCode: '56X2000000000016',
    head: '19360936',
  },
] as any[];
