const KEY = `oidc.user:${import.meta.env.VITE_AUTHORITY}:${import.meta.env.VITE_CLIENT_ID}`;

export const getSessionPayload = () => {
  try {
    return JSON.parse(sessionStorage.getItem(KEY));
  } catch (err) {
    console.error(err);
    return null;
  }
};

const useAuth = () => {
  const payload = getSessionPayload();

  // TODO fetch user
  // const fetchUser = async (token?: string) => {
  //   const jwtToken = token || sessionStorage.getItem('jwtToken');
  //
  //   if (jwtToken) {
  //     const { sub } = JSON.parse(atob(jwtToken.split('.')[1]));
  //
  //     if (sub) {
  //       const user = await getUser(sub).unwrap();
  //
  //       dispatch(signInSuccess(user));
  //
  //       return { loading: isLoading, user };
  //     }
  //   }
  //   return { loading: false, user: null };
  // };

  return {
    user: payload,
  };
};

export default useAuth;
