import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { User } from '@/features/users/types';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: undefined,
    isAuthorized: false,
  },
  reducers: {
    signOut: state => {
      state.isAuthorized = false;
      state.user = undefined;
    },
    signInSuccess: (state, { payload }: PayloadAction<User>) => {
      state.isAuthorized = true;
      state.user = payload;
    },
  },
  selectors: {
    isAuthorizedSelector: state => state.isAuthorized,
    meSelector: state => state.user,
  },
});

export const { signInSuccess, signOut } = authSlice.actions;

export const { isAuthorizedSelector, meSelector } = authSlice.selectors;

export default authSlice;
