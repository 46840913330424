import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  isSidebarMinimizedSelector,
  toggleSidebar,
} from '@/features/ui/uiSlice';

const useUi = () => {
  const isSidebarMinimized = useAppSelector(isSidebarMinimizedSelector);
  const dispatch = useAppDispatch();
  const handleToggle = () => dispatch(toggleSidebar());
  return [isSidebarMinimized, handleToggle];
};

export default useUi;
