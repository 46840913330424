import { ReactNode } from 'react';

import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';

import useUi from '../../../../hooks/useUi';

interface NavItemProps {
  icon: ReactNode;
  children: ReactNode;
  path: string;
}

const NavItem = ({ icon, children, path }: NavItemProps) => {
  const [minimized] = useUi();
  return (
    <NavLink
      to={path}
      className={({ isActive }) => clsx('nav-link', isActive ? 'active' : '')}
      style={{
        textDecoration: 'none',
      }}
    >
      <ListItemButton
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: minimized ? 'center' : 'flex-start',
          alignItems: 'center',
          padding: '4px 8px',
          borderRadius: '4px',
          '.active > &': {
            background: theme => theme.palette.neutral[200],
            '&:hover': {
              background: theme => theme.palette.neutral[400],
            },
            '.MuiSvgIcon-root': {
              fill: theme => theme.palette.neutral[700],
            },
          },
          '&:hover': {
            background: 'rgba(255,255,255, 0.1)',
          },
          '.MuiSvgIcon-root': {
            width: 18,
            height: 18,
            fill: theme => theme.palette.common.white,
          },
        }}
      >
        {icon}
        {!minimized && (
          <Typography
            variant='body2'
            sx={{
              color: theme => theme.palette.common.white,
              whiteSpace: 'nowrap',
              '.active &': {
                color: theme => theme.palette.neutral[700],
              },
            }}
          >
            {children}
          </Typography>
        )}
      </ListItemButton>
    </NavLink>
  );
};

export default NavItem;
