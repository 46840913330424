import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { generateId } from '@/features/utils';

import data from './mock';
import { Subdivision } from './types';

const subdivisionsSlice = createSlice({
  name: 'subdivisions',
  initialState: {
    data,
  },
  reducers: {
    getSubdivisionSuccess: (
      state,
      { payload }: PayloadAction<Subdivision[]>,
    ) => {
      state.data = payload;
    },
    addSubdivisionSuccess: (state, { payload }: PayloadAction<Subdivision>) => {
      state.data.push({
        id: generateId(state),
        ...payload,
      });
    },
    updateSubdivisionSuccess: (
      state,
      { payload }: PayloadAction<Subdivision>,
    ) => {
      const idx = state.data.findIndex(org => org.id === payload.id);
      state.data[idx] = payload;
    },
  },
  selectors: {
    subdivisionsSelector: (state): Subdivision[] => state.data,
    subdivisionByIdSelector: (state, id: string): Subdivision | undefined =>
      id
        ? (state.data.find(org => org.id === id) as Subdivision | undefined)
        : undefined,
  },
});

export const {
  getSubdivisionSuccess,
  addSubdivisionSuccess,
  updateSubdivisionSuccess,
} = subdivisionsSlice.actions;

export const { subdivisionsSelector, subdivisionByIdSelector } =
  subdivisionsSlice.selectors;

export default subdivisionsSlice;
