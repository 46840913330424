export enum UserRoles {
  Metrologist = 'Метролог',
  Dispatcher = 'Диспетчер',
  OrganisationAdmin = 'Адміністратор організації',
  DivisionDirector = 'Начальник відділу',
  Admin = 'Адміністратор',
}

export enum UserActions {
  CounterpartiesPreview = 'Перегляд Контрагентів',
  OrganizationsPreview = 'Перегляд Організацій',
  CounterpartiesCreation = 'Створення Контрагентів',
}

export interface User {
  id: string;
  name: string;
  email: string;
  active: boolean;
  role: UserRoles[];
  actions: UserActions[];
  invitationDate?: string;
  startDate?: string;
  endDate?: string;
}
