import { UserActions, UserRoles } from '@/features/users/types';

export default [
  {
    id: 'ТИС000001',
    name: 'Савченко Олександр Миколайович',
    active: false,
    email: 'test1@gmail.com',
    role: [UserRoles.Admin, UserRoles.Dispatcher, UserRoles.Metrologist],
    actions: [
      UserActions.CounterpartiesPreview,
      UserActions.OrganizationsPreview,
    ],
    invitationDate: '2024-09-01T06:10:28.564Z',
  },
  {
    id: 'ТИС000002',
    name: 'Савченко Олександр Миколайович',
    active: true,
    position: 'Фахівець відділу інспектування',
    email: 'test2@gmail.com',
    role: [UserRoles.Admin, UserRoles.Dispatcher, UserRoles.Metrologist],
    actions: [
      UserActions.CounterpartiesPreview,
      UserActions.OrganizationsPreview,
    ],
    invitationDate: '2024-09-01T06:10:28.564Z',
    startDate: '2024-09-02T06:10:28.564Z',
  },
  {
    id: 'ТИС000003',
    name: 'Савченко Олександр Миколайович',
    active: true,
    email: 'test3@gmail.com',
    role: [UserRoles.Admin, UserRoles.Dispatcher, UserRoles.Metrologist],
    actions: [
      UserActions.CounterpartiesPreview,
      UserActions.OrganizationsPreview,
    ],
    startDate: '2024-09-02T06:10:28.564Z',
  },
  {
    id: 'ТИС000004',
    name: 'Савченко Олександр Миколайович',
    active: false,
    email: 'test4@gmail.com',
    role: [UserRoles.Admin, UserRoles.Dispatcher, UserRoles.Metrologist],
    actions: [
      UserActions.CounterpartiesPreview,
      UserActions.OrganizationsPreview,
    ],
    invitationDate: '2024-09-01T06:10:28.564Z',
    startDate: '2024-09-02T06:10:28.564Z',
    endDate: '2024-09-05T06:10:28.564Z',
  },
];
