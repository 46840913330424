import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { generateId } from '@/features/utils';

import data from './mock';
import { Employee } from './types';

const employeesSlice = createSlice({
  name: 'employees',
  initialState: {
    data,
  },
  reducers: {
    getEmployeeSuccess: (state, { payload }: PayloadAction<Employee[]>) => {
      state.data = payload;
    },
    addEmployeeSuccess: (state, { payload }: PayloadAction<Employee>) => {
      state.data.push({
        id: generateId(state),
        ...payload,
      });
    },
    updateEmployeeSuccess: (state, { payload }: PayloadAction<Employee>) => {
      const idx = state.data.findIndex(org => org.id === payload.id);
      state.data[idx] = payload;
    },
  },
  selectors: {
    employeesSelector: state => state.data,
    employeeByIdSelector: (state, id: string): Employee | undefined =>
      id ? state.data.find(org => org.id === id) : undefined,
  },
});

export const { getEmployeeSuccess, addEmployeeSuccess, updateEmployeeSuccess } =
  employeesSlice.actions;

export const { employeesSelector, employeeByIdSelector } =
  employeesSlice.selectors;

export default employeesSlice;
