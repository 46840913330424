import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosRequestConfig } from 'axios';

import http from '@/utils/http';

export type AxiosBaseQueryRes = BaseQueryFn<
  {
    url: string;
    method?: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
  },
  unknown,
  unknown
>;

const axiosBaseQuery = (
  { baseUrl }: { baseUrl: string } = { baseUrl: '' },
): AxiosBaseQueryRes => {
  return async ({ url, method, data, params, headers }: AxiosRequestConfig) => {
    try {
      const result = await http({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      } as AxiosRequestConfig);
      return { data: result.data };
    } catch (error) {
      return {
        error: {
          status: error.response?.status,
          data: error.response?.data || error.message,
        },
      };
    }
  };
};

export default axiosBaseQuery;
