import { ReactNode } from 'react';

import useUi from '@/hooks/useUi';

import BoltOutlined from '@mui/icons-material/BoltOutlined';
import DashboardOutlined from '@mui/icons-material/DashboardOutlined';
import InsertDriveFileOutlined from '@mui/icons-material/InsertDriveFileOutlined';
import PersonOutlined from '@mui/icons-material/PersonOutlined';
import PieChartOutlined from '@mui/icons-material/PieChartOutlined';
import TextSnippetOutlined from '@mui/icons-material/TextSnippetOutlined';
import SvgIcon from '@mui/joy/SvgIcon';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import OrgsIcon from '@assets/orgs.svg?react';

import NavCategory from './NavCategory';

type NavDivider = { type: 'divider'; show?: (v: boolean) => boolean };

type NavItem = {
  type: 'nav';
  title?: string;
  items: { icon: ReactNode; title: string; path: string }[];
};

type Nav = (NavDivider | NavItem)[];

const nav: Nav = [
  {
    type: 'nav',
    items: [
      {
        icon: (
          <SvgIcon sx={{ width: 18, height: 18, color: 'common.white' }}>
            <OrgsIcon />
          </SvgIcon>
        ),
        title: 'Мої організації',
        path: '/',
      },
    ],
  },
  { type: 'divider' },
  {
    type: 'nav',
    title: 'Комерційні споживачі',
    items: [
      { icon: <PersonOutlined />, title: 'Контрагенти', path: '/' },
      { icon: <BoltOutlined />, title: 'Договори', path: '/counterparts' },
      {
        icon: <BoltOutlined />,
        title: 'Комерційні точки обліку',
        path: '/counterparts',
      },
      {
        icon: <BoltOutlined />,
        title: 'Акти по точкам обліку',
        path: '/counterparts',
      },
    ],
  },
  { type: 'divider', show: isMinimized => isMinimized },
  {
    type: 'nav',
    title: 'ГРС',
    items: [
      { icon: <PieChartOutlined />, title: 'ГРС', path: '/' },
      {
        icon: <DashboardOutlined />,
        title: 'Нитки ГРС',
        path: '/counterparts',
      },
      {
        icon: <DashboardOutlined />,
        title: 'Акти по точкам обліку ГРС',
        path: '/counterparts',
      },
      {
        icon: <DashboardOutlined />,
        title: 'Сектори',
        path: '/counterparts',
      },
      {
        icon: <DashboardOutlined />,
        title: 'Маршрути',
        path: '/counterparts',
      },
    ],
  },
  { type: 'divider' },
  {
    type: 'nav',
    items: [
      { icon: <TextSnippetOutlined />, title: 'Опитування модемів', path: '/' },
      {
        icon: <InsertDriveFileOutlined />,
        title: 'Оперативний облік',
        path: '/counterparts',
      },
      {
        icon: <InsertDriveFileOutlined />,
        title: 'Фактичний облік',
        path: '/counterparts',
      },
      {
        icon: <InsertDriveFileOutlined />,
        title: 'Аналітика',
        path: '/counterparts',
      },
    ],
  },
];

const Nav = () => {
  const [minimized] = useUi();
  return (
    <Stack gap={2} alignItems='flex-start'>
      {nav.map(({ type, ...category }, idx) => {
        if (type === 'nav' && 'items' in category) {
          return <NavCategory key={idx} {...category} />;
        }

        if (type === 'divider') {
          if (
            typeof minimized === 'boolean' &&
            'show' in category &&
            !category.show(minimized)
          ) {
            return null;
          }
          return (
            <Divider
              key={idx}
              flexItem
              orientation='horizontal'
              sx={{
                borderColor: theme => theme.palette.neutral['600'],
              }}
            />
          );
        }
      })}
    </Stack>
  );
};

export default Nav;
