import { GridRowId } from '@mui/x-data-grid';

const enum Pages {
  ROOT = '/',
  CHANGE_PASSWORD = '/profile/change-password',
  ORGANIZATIONS = '/organizations',
  USERS = '/users',

  // NOT IN THE ROUTER
  EMPLOYEES_PART = `employees`,
  SUBDIVISIONS_PART = `subdivisions`,
}

export const getUserURL = (id: string | GridRowId) => `${Pages.USERS}/${id}`;

export const getOrganizationURL = (id: string | GridRowId) =>
  `${Pages.ORGANIZATIONS}/${id}`;

export const getSubdivisionURL = (
  orgId: string | GridRowId,
  id: string | GridRowId,
) => `${getOrganizationURL(orgId)}/${Pages.SUBDIVISIONS_PART}/${id}`;

interface GetEmployeesURLProps {
  orgId: string | GridRowId;
  subId?: string | GridRowId;
}

export const getEmployeesURL = ({ orgId, subId }: GetEmployeesURLProps) => {
  let part = '';
  if (subId) {
    part = `${Pages.SUBDIVISIONS_PART}/${subId}/`;
  }
  return `${getOrganizationURL(orgId)}/${part}${Pages.EMPLOYEES_PART}`;
};

export const getEmployeeURL = ({
  orgId,
  subId,
  id,
}: GetEmployeesURLProps & {
  id: string | GridRowId;
}) => `${getEmployeesURL({ orgId, subId })}/${id}`;

export default Pages;
