import React, { ReactNode } from 'react';

import { createBrowserRouter } from 'react-router-dom';
import { RouteObject } from 'react-router/dist/lib/context';

import Pages from '@/pages';

import PageLayout from './components/PageLayout';

const AuthCallback = React.lazy(() => import('@/pages/auth/AuthCallback'));
const ChangePassword = React.lazy(() => import('@/pages/auth/ChangePassword'));
const Organizations = React.lazy(() => import('@/pages/Organizations'));
const OrganizationDetails = React.lazy(
  () => import('@/pages/OrganizationDetails'),
);
const OrganizationInformation = React.lazy(
  () => import('@/pages/OrganizationDetails/views/Information'),
);
const OrganizationEmployees = React.lazy(
  () => import('@/pages/OrganizationDetails/views/Employees'),
);
const OrganizationEmployeeDetails = React.lazy(
  () => import('@/forms/EmployeeForm'),
);
const OrganizationSubdivisions = React.lazy(
  () => import('@/pages/OrganizationDetails/views/Subdivisions'),
);
const OrganizationSubdivisionDetails = React.lazy(
  () => import('@/pages/OrganizationDetails/views/SubdivisionDetails'),
);
const OrganizationSubdivisionDetailsInformation = React.lazy(
  () =>
    import(
      '@/pages/OrganizationDetails/views/SubdivisionDetails/views/Information'
    ),
);
const Users = React.lazy(() => import('@/pages/Users'));
const UserDetails = React.lazy(() => import('@/forms/UserForm'));

export interface Breadcrumbs {
  title: string;
  path: string;
}

interface RouteLayer {
  path: string;
  element: ReactNode;
  title?: ReactNode;
  crumbs?: Breadcrumbs[];
  children?: RouteObject[];
}

const routes: RouteLayer[] = [
  {
    path: Pages.ROOT,
    element: <Organizations />,
  },
  {
    path: `${Pages.ORGANIZATIONS}/:orgId`,
    element: <OrganizationDetails />,
    children: [
      {
        path: '',
        element: <OrganizationInformation />,
      },
      {
        path: Pages.EMPLOYEES_PART as string,
        element: <OrganizationEmployees />,
        children: [
          {
            path: `:employeeId`,
            element: <OrganizationEmployeeDetails />,
          },
        ],
      },
      {
        path: Pages.SUBDIVISIONS_PART as string,
        element: <OrganizationSubdivisions />,
      },
    ],
  },
  {
    path: `${Pages.ORGANIZATIONS}/:orgId/${Pages.SUBDIVISIONS_PART}/:subId`,
    element: <OrganizationSubdivisionDetails />,
    children: [
      { path: '', element: <OrganizationSubdivisionDetailsInformation /> },
      {
        path: Pages.EMPLOYEES_PART as string,
        element: <OrganizationEmployees />,
        children: [
          {
            path: `:employeeId`,
            element: <OrganizationEmployeeDetails />,
          },
        ],
      },
    ],
  },
  {
    path: Pages.USERS,
    element: <Users />,
    children: [
      {
        path: ':userId',
        element: <UserDetails />,
      },
    ],
  },
  {
    path: Pages.CHANGE_PASSWORD,
    element: <ChangePassword />,
  },
];

export const getRoutes = () =>
  createBrowserRouter([
    // Page for oidc authorization flow
    {
      path: '/callback',
      element: <AuthCallback />,
    },
    {
      path: '/',
      element: <PageLayout />,
      children: routes.map(({ path, element, children }) => ({
        path,
        element,
        children,
      })),
    },
  ]);
