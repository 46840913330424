import { ReactNode } from 'react';

import { AuthProvider, useAuth } from 'react-oidc-context';

const PrivateRoute = ({ children }) => {
  const auth = useAuth();

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div>Signing you in...</div>;
    case 'signoutRedirect':
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (!auth.isAuthenticated) {
    return auth.signinRedirect();
  }

  return children;
};

const Provider = ({ children }: { children: ReactNode }) => {
  // TODO: NEED TO REMOVE for debug purpose only
  console.log({
    authority: import.meta.env.VITE_AUTHORITY,
    client_id: import.meta.env.VITE_CLIENT_ID,
    redirect_uri: import.meta.env.VITE_REDIRECT_URI,
    post_logout_redirect_uri: import.meta.env.VITE_POST_LOGOUT_REDIRECT_URI,
    scope: import.meta.env.VITE_SCOPE,
  });
  return (
    <AuthProvider
      authority={import.meta.env.VITE_AUTHORITY}
      client_id={import.meta.env.VITE_CLIENT_ID}
      redirect_uri={import.meta.env.VITE_REDIRECT_URI}
      post_logout_redirect_uri={import.meta.env.VITE_POST_LOGOUT_REDIRECT_URI}
      scope={import.meta.env.VITE_SCOPE}
      onSigninCallback={() => window.location.replace('/')}
    >
      <PrivateRoute>{children}</PrivateRoute>
    </AuthProvider>
  );
};

export default Provider;
